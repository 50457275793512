import React from "react";
import Layout from "../components/layout";
import LaunchIcon from "@material-ui/icons/Launch";
import Breadcrumb from "../components/breadcrumb";
import ProductGrid from "../components/product-grid/product-grid";
import AlgoliaSearch from "algoliasearch";
import { GetSimplePathObj } from "../helpers/data-formatting";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { graphql, Link, PageProps } from "gatsby";
import { GQLQuery } from "../types";
import GlobalConstants, {
  Products as Constants,
  Algolia,
} from "../helpers/constants";

const UseStyles = makeStyles({
  linkHeader: {
    width: "auto",
    color: "white",
    backgroundColor: "#AFBD21",
    padding: ".35rem 0 .35rem 1.75rem",
  },

  links: {
    padding: ".35rem 0 .35rem .5rem",
    width: "100%",

    "& a": {
      color: "black",

      "& svg": {
        height: "1rem",
      },
    },
  },
});

type PageInputProps = { crystallize: GQLQuery };
type ProductsProps = PageProps<PageInputProps>;

const Products = (props: ProductsProps): JSX.Element => {
  const Classes = UseStyles();
  const QueryData = props.data.crystallize.catalogue;
  const PathObj = GetSimplePathObj(GlobalConstants.Products);
  const [State, SetState] = React.useState([]);

  /* Results must be searched/returned/loaded on page-load */
  React.useEffect(() => {
    const Client = AlgoliaSearch(Algolia.AppId, Algolia.ApiKey);
    const Index = Client.initIndex(Algolia.IndexName);
    Index.search("").then(({ hits }) => SetState(hits));
  }, [QueryData.path]);

  return (
    <Layout currentPage="/products">
      <Breadcrumb pathObj={PathObj} />
      <Grid item xs={12} sm={4} md={3}>
        <p>{Constants.ProductsHeaderText}</p>
        <Typography variant="h6" className={Classes.linkHeader}>
          {Constants.OurProductsText}
        </Typography>
        {QueryData.children.map((node, index) => {
          if (!Constants.ExcludedFolders.includes(node.name)) {
            return (
              <Typography
                variant="subtitle1"
                key={index}
                className={Classes.links}
              >
                <Link to={"/products" + node.path}>
                  <LaunchIcon />
                  {node.name}
                </Link>
              </Typography>
            );
          } else {
            return null;
          }
        })}
        <Typography variant="subtitle1" className={Classes.links}>
          <Link to={"/products/usa-made"}>
            <LaunchIcon />
            {Constants.UsaMadeText}
          </Link>
        </Typography>
      </Grid>
      <ProductGrid gridItems={State} showBrand={true} />
    </Layout>
  );
};

export const Query = graphql`
  query {
    crystallize {
      catalogue {
        children {
          name
          path
        }
      }
    }
  }
`;

export default Products;
